<template>
  <div>
    <v-container>
      <h1 class="text-center">My <span class="underline-text">Wallet</span></h1>
      <p class="text-center mt-3 mb-10" style="color: #909090">
        A brief summary of your crypto portfolio
      </p>
      <div class="white pa-5 mb-10">
        <v-simple-table class="no-lines-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Exchange</th>
                <th class="text-left">Coin</th>

                <th class="text-left">Balance</th>

                
              </tr>
            </thead>
            <tbody class="no-lines-body">
              <tr
                v-for="(item, index) in COIN_BALANCE"
                :key="item.name"
                class=""
                style="font-family: 'Poppins', sans-serif"
              >
                <td class="py-6">{{ index + 1 }}</td>
                <td>
                  <div class="d-flex align-center">
                    <img
                      :src=getExchangeLogoUrl(item.exchange)
                      class="rounded-circle"
                      width="40"
                      height="40"
                    />
                    <div class="ms-3">
                      <p class="ma-0">{{item.exchange}}</p>
                      <!-- <span class="fw-normal" style="color: #A6A6A6;"></span> -->
                    </div>
                  </div>
                </td>
                <td>
                  <p>
                    <i class="cc mx-1" :class="item.coin"></i>{{ item.coin }}
                  </p>
                </td>
                <td>
                  <p>
                    {{ item.coinsRemaining }}
                  </p>
                </td>

               
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["COIN_BALANCE"]),
  },
  methods: {
    getExchangeLogoUrl(exchangeName) {
      return require(`@/assets/icons/${exchangeName
        .toLowerCase()
        .replace(" ", "")}.png`);
    },
  },
};
</script>

<style scoped>
p {
  margin: 0px;
}
no-lines-table table,
.no-lines-table tbody,
.no-lines-table tr,
.no-lines-table td {
  border: none !important;
}

.no-lines-row {
  border-bottom: none !important;
}

.underline-text {
  position: relative;
  display: inline-block;
  color: #4aabab;
}
.underline-text::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: -20px;
  right: 0;
  stroke: 3px;
  width: 150px;
  height: 20px;
  background-image: url("../assets/welcome.png");
  /* filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25)); */
  background-repeat: no-repeat;
  background-size: cover;
}
</style>